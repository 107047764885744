import { createSelector, createSlice } from "@reduxjs/toolkit";
import toast from 'react-hot-toast';

const initialState = {
  alertSettings: null,
  status: "idle",
  error: null,
  settings: {}
};

const parentSelector = state => state?.["settings"]

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    fetchAlertSettingsRequest: (state) => {
      state.status = "loading";
    },
    fetchAlertSettingsSuccess: (state, action) => {
      state.status = "succeeded";
      state.alertSettings = action.payload;
    },
    fetchAlertSettingsFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    updateAlertSettingsRequest: (state) => {
      state.status = "loading";
    },
    updateAlertSettingsSuccess: (state, action) => {
      state.status = "succeeded";
      toast.success('Settings Updated');
      state.alertSettings = action.payload;
    },
    updateAlertSettingsFailure: (state, action) => {
      state.status = "failed";
      toast.error("Something went wrong");
      state.error = action.payload;
    },
    setSettings: (state, action) => {
      return {
        ...state,
        settings: action.payload,
      };
    },
  },
});

const getSettings = createSelector(parentSelector, state => {
  return state?.settings
})


const selectors = {
  getSettings,
}
export const settingsActions = { actions: settingsSlice.actions, selectors };

export default settingsSlice;
