import { InfoSharp } from '@mui/icons-material';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import React, { memo } from 'react';
import text from '../shared/css/text.module.scss';
import CustomPopover from './Popover/CustomPopover';
import shared from './shared.module.scss';

const CustomTitle = ({ title, count, isCount = true, infoText }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Grid container justifyContent={'flex-start'} alignItems={'center'} gap={1} marginTop={'3vh'}>
      <Typography className={text.heading3}>{title}</Typography>
      {infoText &&
        <IconButton
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{ padding: 0 }}
        >
          <InfoSharp fontSize="small" />
        </IconButton>
      }

      {isCount ? <Box className={shared.badge} >{count}</Box> : null}

      <CustomPopover open={open} anchorEl={anchorEl} handlePopoverClose={handlePopoverClose} popoverText={infoText} />
    </Grid>
  )
}

export default memo(CustomTitle);
