import { Box, Grid } from '@mui/material';
import MyCard from 'components/shared/Card/Card';
import CustomDialog from 'components/shared/Dialog/CustomDialog';
import { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import GraphTitle from './GraphTitle';
import tableStyles from '../../shared/Tables/MyTable/myTable.module.scss';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uiActions } from '../../../redux/slice/ui.slice';
import { alertsActions } from '../../../redux/slice/alerts.slice';
import theme from 'components/utils/theme';

const SecurityAlerts = ({ alerts }) => {
    const options = useMemo(() => {
        if (alerts?.types?.length) {
            return {
                dataLabels: {
                    enabled: false,
                },
                chart: {
                    type: 'bar',
                    stacked: true,
                    toolbar: {
                        show: false,
                    },
                    events: {
                        dataPointSelection: (event, chartContext, { dataPointIndex, seriesIndex }) => {
                            const alertType = alerts?.types?.[dataPointIndex];
                            const alertLevel = alerts?.chartData?.[seriesIndex]?.name
                            handleClickBar(alertType, alertLevel)
                        }
                    }
                },
                colors: [theme.palette.success.main, theme.palette.warning.main, theme.palette.error.main],
                plotOptions: {
                    bar: {
                        borderRadius: 5,
                        columnWidth: '40px',
                    }
                },
                grid: {
                    show: true,
                    borderColor: '#e0e0e0',
                    strokeDashArray: 4,
                    position: 'back',
                    xaxis: {
                        lines: {
                            show: false,
                        }
                    },
                    yaxis: {
                        lines: {
                            show: true,
                        }
                    }
                },
                xaxis: {
                    categories: alerts?.types,
                    show: true,
                    axisBorder: {
                        show: false,
                        color: '#808080',
                        height: 1,
                        offsetX: 0,
                        offsetY: 1,
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#808080',
                        width: 6,
                    },
                    title: {
                        text: 'Categories',
                        style: {
                            color: '#808080',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: 'bold',
                            padding: 10,
                            backgroundColor: '#fff',
                            border: '1px solid #000',
                            borderRadius: '4px',
                        }
                    }
                },
                yaxis: {
                    min: 0,
                    max: 100,
                    tickAmount: 5,
                    labels: {
                        formatter: function (val) {
                            return Math.floor(val);
                        }
                    },
                    axisBorder: {
                        show: true,
                        strokeDashArray: 4,
                        color: '#808080',
                        offsetX: -1,
                    },
                    axisTicks: {
                        show: true,
                        borderType: 'solid',
                        color: '#808080',
                        width: 6,
                    },
                    title: {
                        text: 'No of Alerts',
                        style: {
                            color: '#808080',
                            fontSize: '14px',
                            fontFamily: 'Poppins',
                            fontWeight: 'bold',
                            padding: 10,
                            backgroundColor: '#fff',
                            border: '1px solid #000',
                            borderRadius: '4px',
                        }
                    },
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    show: false
                },
            };
        }
        return {};
    }, [alerts]);

    const [isFullScreen, setIsFullScreen] = useState(false);

    const handleToggleScreen = () => {
        setIsFullScreen((prevState) => !prevState);
    };

    const chartData = alerts?.chartData?.length ? alerts.chartData : [];


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleClickBar = (type, level) => {
        dispatch(uiActions.actions.setSelectedMultiTab(1));
        dispatch(alertsActions.actions.setSelectedLevel(level))
        navigate("/");
    }

    return (
        <>
            <MyCard height={'350px'}>
                <GraphTitle showTimeMenu={false} showFullScreenBtn={true} handleClickFullScreen={handleToggleScreen} title={'Security Alerts Overview'} />
                {chartData.length ? (
                    <Chart options={options} series={chartData} type="bar" height={280} />
                ) : (
                    <Box className={tableStyles.noData}><ContentPasteOffIcon /> No Security Alerts</Box>
                )}
            </MyCard>
            <CustomDialog fullScreen={true} title={'Security Alerts Overview'} isOpen={isFullScreen} handleToggle={handleToggleScreen} maxWidth={'xl'}>
                <Grid height={'100%'}>
                    {chartData.length ? (
                        <Chart options={options} series={chartData} type="bar" height={"100%"} />
                    ) : (
                        <Box className={tableStyles.noData}><ContentPasteOffIcon /> No Security Alerts</Box>
                    )}
                </Grid>
            </CustomDialog>
        </>
    );
};

export default SecurityAlerts;
