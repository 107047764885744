import toast from "react-hot-toast";
import { put, select, takeEvery } from "redux-saga/effects";
import { SOMETHING_WENT_WRONG } from "../../static/constants";
import {
  deleteDevicesServ,
  getDeviceLocationsServ,
  getDeviceTokenServ,
  getDevicesServ,
  getSingleDeviceLocationsServ,
  getSingleDeviceServ,
  updateDeviceNameServ
} from "../services/devices.service";
import { devicesActions } from "../slice/devices.slice";
import {
  DELETE_DEVICES,
  GET_DEVICES,
  GET_DEVICES_LOCATION,
  GET_DEVICE_TOKEN,
  GET_SINGLE_DEVICE,
  GET_SINGLE_DEVICE_LOCATION,
  UPDATE_DEVICE_NAME
} from "../types/devices.types";

export function* getDevices(action) {
  try {
    const devices = yield getDevicesServ(action.payload);
    if (devices) {
      yield put(devicesActions.actions.setDevices(devices));
    } else {
      toast.error("Failed to load Devices");
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}

export function* getSingleDevice(action) {
  try {
    const device = yield getSingleDeviceServ(action.payload);
    if (device) {
      yield put(devicesActions.actions.setCurrentDevice(device));
    } else {
      toast.error("Failed to load Devices");
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}

export function* getDeviceTokenSaga(action) {
  try {
    const response = yield getDeviceTokenServ(action.payload);
    if (response) {
      const devices = yield select(devicesActions.selectors.getDevices);
      yield put(devicesActions.actions.setDevicesToken(response?.token));
      yield put(devicesActions.actions.setDevices([response, ...devices]));
    } else {
      toast.error(SOMETHING_WENT_WRONG);
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}

export function* getDeviceLocations(action) {
  try {
    const response = yield getDeviceLocationsServ(action.payload);
    if (response) {
      yield put(devicesActions.actions.setDeviceLocation(response?.data));
    } else {
      yield put(devicesActions.actions.setDeviceLocation([]));
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}

export function* getSingleDeviceLocation(action) {
  try {
    const response = yield getSingleDeviceLocationsServ(action.payload);
    if (response) {
      yield put(devicesActions.actions.setSingleDeviceLocation(response?.data));
    }
  } catch (err) {
    console.log("err", err);
  }
}

export function* deleteDevices(action) {
  try {
    const response = yield deleteDevicesServ(action.payload);
    if (response) {
      const devices = yield select(devicesActions.selectors.getDevices);
      const updatedDevices = devices.filter(
        (device) => !response.ids.includes(device._id)
      );
      yield put(devicesActions.actions.setDevices(updatedDevices));
      toast.success(
        `${response.ids?.length} Device${response.ids?.length >= 1 ? "" : "s"
        } Deleted Successfully`
      );
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}


export function* updateDeviceName(action) {
  try {
    const payload = {
      deviceName: action.payload?.deviceName,
      deviceId: action.payload?._id
    }
    console.log("payload", payload)
    const response = yield updateDeviceNameServ(payload);
    if (response) {
      const devices = yield select(devicesActions.selectors.getDevices);
      console.log("dev", devices);
      console.log("res", response);

      const updatedDevices = devices.map((device) =>
        device._id === action.payload._id ? action.payload : device
      );
      toast.success("Device Name Updated")
      yield put(devicesActions.actions.setDevices(updatedDevices));
    } else {
      toast.error("Failed to load Devices");
    }
  } catch (err) {
    console.log("err", err);
    toast.error(SOMETHING_WENT_WRONG);
  }
}

export function* watchDeviceAsync() {
  yield takeEvery(GET_DEVICES, getDevices);
  yield takeEvery(GET_DEVICE_TOKEN, getDeviceTokenSaga);
  yield takeEvery(GET_SINGLE_DEVICE, getSingleDevice);
  yield takeEvery(GET_SINGLE_DEVICE_LOCATION, getSingleDeviceLocation);
  yield takeEvery(GET_DEVICES_LOCATION, getDeviceLocations);
  yield takeEvery(DELETE_DEVICES, deleteDevices);
  yield takeEvery(UPDATE_DEVICE_NAME, updateDeviceName);
}
