import React from 'react'

const TickCircle = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="25" cy="25" r="24.5" stroke="#0C5E9B" />
            <path d="M20.6248 38C19.931 38 19.2629 37.7173 18.769 37.2091L11.7688 30.0089C10.7437 28.9551 10.7437 27.2446 11.7688 26.1908C12.7932 25.1362 14.4561 25.1362 15.4805 26.1908L20.3401 31.1889L34.2742 12.0795C35.1461 10.8868 36.7927 10.6462 37.9493 11.5395C39.1097 12.4345 39.3446 14.1267 38.4751 15.3195L22.7248 36.9192C22.2682 37.5458 21.5698 37.9373 20.8114 37.9921C20.7492 37.9968 20.687 37.9992 20.6255 37.9992L20.6248 38Z" fill="#0C5E9B" />
        </svg>

    )
}

export default TickCircle
