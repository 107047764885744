import { call, put, select, takeEvery } from "redux-saga/effects";
import { fetchAlertSettingsService, getSettingsService, updateAlertSettingsService, updateSettingsService } from "../services/settings.service";
import { settingsActions } from "../slice/settings.slice";
import { FETCH_ALERT_SETTINGS, GET_SETTINGS, UPDATE_ALERT_SETTINGS, UPDATE_SETTINGS } from "../types/settings.types";
import { authActions } from "../slice/auth.slice";
import toast from "react-hot-toast";

function* fetchAlertSettingsSaga() {
  try {
    const currentUser = yield select(authActions.selectors.getCurrentUser);
    const userId = currentUser._id;
    const alertSettings = yield call(fetchAlertSettingsService, userId);
    yield put(settingsActions.actions.fetchAlertSettingsSuccess(alertSettings));
  } catch (error) {
    yield put(settingsActions.actions.fetchAlertSettingsFailure(error.message));
  }
}

function* updateAlertSettingsSaga(action) {
  try {
    const updatedSettings = yield call(updateAlertSettingsService, action.payload);
    yield put(settingsActions.actions.updateAlertSettingsSuccess(updatedSettings));
  } catch (error) {
    yield put(settingsActions.actions.updateAlertSettingsFailure(error.message));
  }
}

function* getSettingsSaga() {
  try {
    const settings = yield getSettingsService();
    yield put(settingsActions.actions.setSettings(settings));
  } catch (error) {
    console.log("Error occurred in Settings API", error)
  }
}

function updateSettingsSaga(action) {
  toast.promise(
    updateSettingsService(action.payload),
    {
      loading: 'Updating Settings...',
      success: 'Settings Updated successfully',
      error: 'Failed to Update Settings',
    }
  ).then((settings) => {
    put(settingsActions.actions.setSettings(settings));
  }).catch((error) => {
    console.error("Failed to delete group", error);
  });
}

export function* watchSettingsAsync() {
  yield takeEvery(FETCH_ALERT_SETTINGS, fetchAlertSettingsSaga);
  yield takeEvery(UPDATE_ALERT_SETTINGS, updateAlertSettingsSaga);
  yield takeEvery(GET_SETTINGS, getSettingsSaga);
  yield takeEvery(UPDATE_SETTINGS, updateSettingsSaga);
}
