import React from "react";
import { Controller } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FormControl, FormHelperText } from "@mui/material";
import forms from './forms.module.scss';
import text from '../css/text.module.scss';

const RHFTimeSelector = ({ control, name, label, errors, required = false, disabled = false }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl error={!!errors?.[name]?.message} required={required} fullWidth>

                    {label && (
                        <label className={`${text.formLabel} ${forms.timeLabel}`}>
                            {label}{required && <span className={forms.estaric}>*</span>}
                        </label>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            slotProps={{
                                textField: {
                                    size: "small",
                                    className: disabled ? forms.disabledInput : "",
                                },
                            }}
                            value={field.value || null}
                            disabled={disabled}
                            onChange={(newValue) => field.onChange(newValue)}
                            renderInput={(params) => (
                                <>
                                    {params}
                                    {errors?.[name] && (
                                        <FormHelperText>{errors[name].message}</FormHelperText>
                                    )}
                                </>
                            )}
                        />
                    </LocalizationProvider>
                </FormControl>
            )}
        />
    );
};

export default RHFTimeSelector;
