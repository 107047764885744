import { Grid, Typography } from "@mui/material";
import { checkUserDevicePermissions } from "components/utils/accessManagement";
import { handleDownloadCsv, handlePrintCsv } from "components/utils/handlers";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getDevicesSocket,
  setUpDeviceSocket,
  unSubscribeSocketTopics,
} from "../../../redux/saga/socket";
import { alertsActions } from "../../../redux/slice/alerts.slice";
import { authActions } from "../../../redux/slice/auth.slice";
import { devicesActions } from "../../../redux/slice/devices.slice";
import { uiActions } from "../../../redux/slice/ui.slice";
import { VERIFY_TWO_FA_OTP } from "../../../redux/types/auth.types";
import {
  GET_DEVICES,
  GET_DEVICES_LOCATION,
  GET_DEVICE_TOKEN,
  UPDATE_DEVICE_NAME,
} from "../../../redux/types/devices.types";
import { FETCH_GROUPS } from "../../../redux/types/group.types";
import { GET_ALL_ALERTS } from "../../../redux/types/multi.types";
import CustomTitle from "../../shared/CustomTitle";
import CustomTableContainer from "../../shared/Tables/CustomTableContainer";
import { getDeviceColumns } from "../../shared/Tables/helper";
import text from "../../shared/css/text.module.scss";
import { TOPICS } from "../../utils/enums";
import theme from "../../utils/theme";
import SecurityAlerts from "../MultiDashboard/SecurityAlerts";
import ThreatComparison from "../MultiDashboard/ThreatComparison";
import AddDeviceDialog from "./AddDeviceDialogue";
import DeviceMap from "./DeviceMap/DeviceMap";
import DeviceStats from "./DeviceStats";
import TwoFADialog from "./TwoFADialog";
import { getMarker, prepareWindowsCommand } from "./helper";

const Device = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(authActions.selectors.getCurrentUser);
  const isTwoFaEnabled = currentUser?.isTwoFaEnabled || false;

  const getDeviceToken = () => {
    const payload = {
      orgId: process.env.REACT_APP_ORG_ID,
    };
    dispatch({ type: GET_DEVICE_TOKEN, payload });
  };

  useEffect(() => {
    getDevicesSocket({
      orgId: process.env.REACT_APP_ORG_ID,
      deviceType: "device"
    });
  }, [currentUser?._id]);

  const allAlerts = useSelector(alertsActions.selectors.getAllAlerts);
  useEffect(() => {
    setUpDeviceSocket(dispatch);
    dispatch({ type: GET_ALL_ALERTS })
    return () => {
      unSubscribeSocketTopics([TOPICS.DEVICES]);
    };
  }, [dispatch]);

  useEffect(() => {
    const payload = {
      orgId: process.env.REACT_APP_ORG_ID,
    };
    dispatch(uiActions.actions.setSelectedNav("Devices"));

    dispatch({ type: GET_DEVICES, payload });
    dispatch({ type: GET_DEVICES_LOCATION, payload });
    dispatch({ type: FETCH_GROUPS });
  }, [dispatch]);

  const devices = useSelector(devicesActions.selectors.getDevices);
  const devicesLocs = useSelector(devicesActions.selectors.getDeviceLocation);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [curlCommand, setCurlCommand] = useState("");
  const [copied, setCopied] = useState(false);
  const [selectedOs, setSelectedOs] = useState("windows");
  const [selectedVersion, setSelectedVersion] = useState("windows11");
  const [selectedDeviceToken, setSelectedDeviceToken] = useState("");
  const [is2FAOpen, setIs2FAOpen] = useState(false);
  const groups = useSelector((state) => state.group.groups);

  const userPermissions = checkUserDevicePermissions(currentUser?._id, groups, currentUser.role);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleVerify2FA = async (data) => {
    const paylaod = {
      twoFaOtp: data,
      email: currentUser?.email,
    };
    dispatch({ type: VERIFY_TWO_FA_OTP, paylaod });
    setIs2FAOpen(false);
  };

  const handleClose2FA = () => {
    setIs2FAOpen(false);
  };

  const [isDeviceOpen, setIsDeviceOpen] = useState(false);

  const handleInstallDevice = (token) => {
    setIsDeviceOpen(!isDeviceOpen);
    setSelectedDeviceToken(token);
    if (!isDeviceOpen) {
      setCurlCommand(prepareWindowsCommand(token));
    } else {
      setCurlCommand("");
    }
  };

  const deviceCols = getDeviceColumns(
    handleInstallDevice,
    dispatch,
    isTwoFaEnabled,
    setIs2FAOpen,
    currentUser
  );

  const handleAddSingledevice = () => {
    if (userPermissions?.ADD_DEVICE) {
      getDeviceToken();
    } else {
      toast.error("Permissions not allowed")
    }
  };

  const markers = useMemo(() => {
    if (devicesLocs?.length) {
      return devicesLocs.map((device) => {
        const loc = device?.loc;
        if (loc) {
          const coordinates = loc.split(",").map(Number)?.reverse();
          const html = getMarker(theme.palette.success.main);
          return { coordinates, html, device: device?.device };
        }
        return null
      })
    }
  }, [devicesLocs])


  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const filteredDevices = useMemo(() => {
    if (!searchTerm) return devices;
    const regex = new RegExp(searchTerm, "i");
    return devices.filter((device) =>
      Object.values(device).some((value) => regex.test(value))
    );
  }, [devices, searchTerm]);

  const handleUpdateDeviceName = (newRow, oldRow) => {
    console.log("Updated Row:", oldRow, ":", newRow);
    if (oldRow?.deviceName === newRow?.deviceName) {
      console.log("No Change in device Name")
      return
    }
    dispatch({ type: UPDATE_DEVICE_NAME, payload: newRow })
  };

  return (
    <>
      <Grid container>
        <Grid container justifyContent={"center"}>
          <Typography className={text.heading1}>
            Device Inventory Management
          </Typography>
        </Grid>
        <br />
        <Grid container spacing={2} marginTop={"3vh"} alignItems="center">
          <Grid item xs={12}>
            <Typography className={text.heading1}>My Devices</Typography>
          </Grid>
        </Grid>
        <Grid
          my={"15px"}
          container
          sx={{ justifyContent: { xl: "space-between", lg: "center" } }}
          spacing={2}
        >
          <Grid item xs={12} md={6} xl={4}>
            <DeviceStats devices={devices} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            {/* <DeviceGraph /> */}
            <SecurityAlerts alerts={allAlerts} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <ThreatComparison alerts={allAlerts?.chartData} />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <DeviceMap markers={markers} center={devicesLocs?.[0]?.loc} />
          </Grid>
        </Grid>
        <CustomTitle title={"Total Devices"} count={filteredDevices?.length} />
        <Grid my={"3vh"} item xs={12}>
          <CustomTableContainer
            deviceCols={deviceCols}
            devices={filteredDevices}
            handleBtn1Click={handleAddSingledevice}
            handleRowUpdate={handleUpdateDeviceName}
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            onSearch={handleSearch}
            onDownload={handleDownloadCsv}
            onPrint={handlePrintCsv}
            isUninstall={true}
            permissions={userPermissions}
          />
        </Grid>
      </Grid>
      <AddDeviceDialog
        curlCommand={curlCommand}
        setCurlCommand={setCurlCommand}
        handleDeviceDialog={handleInstallDevice}
        isDeviceOpen={isDeviceOpen}
        copied={copied}
        setCopied={setCopied}
        selectedOs={selectedOs}
        setSelectedOs={setSelectedOs}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        selectedDeviceToken={selectedDeviceToken}
      />
      <TwoFADialog
        open={is2FAOpen}
        onClose={handleClose2FA}
        onVerify={handleVerify2FA}
      />
    </>
  );
};

export default Device;
