import { Box, CardContent, Grid, TextField, Typography, CircularProgress, InputAdornment } from '@mui/material';
import CustomButton from 'components/shared/Buttons/CustomButton';
import { formatDateBdata } from 'components/utils';
import theme from 'components/utils/theme';
import styles from './apps.module.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_MALWARE_HASHES } from '../../../redux/types/devices.types';
import { applicationsActions } from '../../../redux/slice/applications.slice';
import toast from 'react-hot-toast';
import TickCircle from 'static/svgs/TickCircle';

const MalwareSearch = ({ handleClose }) => {
    const dispatch = useDispatch();
    const malwareAppLoader = useSelector(applicationsActions.selectors.getMalwareSearchLoader);
    const malwareApp = useSelector(applicationsActions.selectors.getMalwareApps);

    const [hash, setHash] = useState('');
    const [searched, setSearched] = useState(false); // To track if a search has been made

    const isValidSHA256 = (input) => /^[a-fA-F0-9]{64}$/.test(input); // Regular expression for SHA256

    const handleMalwareSearch = (e) => {
        e.preventDefault();
        if (!hash?.length) {
            return toast.error("Please enter a hash.");
        }
        if (!isValidSHA256(hash)) {
            return toast.error("Please enter a valid SHA256 hash.");
        }
        setSearched(true);
        dispatch({ type: GET_MALWARE_HASHES, payload: { hash } });
    };

    const handleHashChange = (e) => {
        setHash(e.target.value);
    };

    return (
        <div className={styles.malwareSearchContainer}>
            <form onSubmit={handleMalwareSearch}>
                <Grid container alignItems={'center'} spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            placeholder="Paste App Hash Here"
                            variant="outlined"
                            fullWidth
                            value={hash}
                            onChange={handleHashChange}
                            InputProps={{
                                sx: { height: 48, borderRadius: 2, background: theme.palette.white.dark },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CustomButton
                                            padding={"0px 50px"}
                                            color={theme.palette.background.default}
                                            bgColor={theme.palette.primary.main}
                                            hover={theme.palette.info.main}
                                            label={"Search"}
                                            type={"submit"}
                                            height="35px"
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </form>

            {/* Conditional Rendering for Loader, Initial Message, and Results */}
            <Box mt={4} display="flex" justifyContent="center">
                {malwareAppLoader ? (
                    <div className={styles.noMalwareCard}>
                        <CircularProgress />
                    </div>
                ) : searched ? (
                    Object.keys(malwareApp)?.length ? (
                        <div className={styles.malwareAppCardContainer}>
                            <Typography variant="h6">
                                Malware App Detected
                            </Typography>
                            <div className={styles.malwareAppCard}>
                                <Typography className={styles.cardItem} variant="body2" color="textSecondary">
                                    <strong> • First Seen:</strong> {formatDateBdata(malwareApp.firstSeen)}
                                </Typography>
                                <Typography className={styles.cardItem} variant="body2" color="textSecondary">
                                    <strong> • SHA256 Hash:</strong> {malwareApp.sha256Hash}
                                </Typography>
                                <Typography className={styles.cardItem} variant="body2" color="textSecondary">
                                    <strong> • Reporter:</strong> {malwareApp.reporter}
                                </Typography>
                                <Typography className={styles.cardItem} variant="body2" color="textSecondary">
                                    <strong> • File Name:</strong> {malwareApp.fileName}
                                </Typography>
                                <Typography className={styles.cardItem} variant="body2" color="textSecondary">
                                    <strong> • File Type Guess:</strong> {malwareApp.fileTypeGuess}
                                </Typography>
                                <Typography className={styles.cardItem} variant="body2" color="textSecondary">
                                    <strong> • MIME Type:</strong> {malwareApp.mimeType}
                                </Typography>
                                <Typography className={styles.cardItem} variant="body2" color="textSecondary">
                                    <strong> • Signature:</strong> {malwareApp.signature}
                                </Typography>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.noMalwareCard}>
                            <TickCircle />
                            <div className={styles.noMalwareText}>
                                <Typography variant="h6" align="center">
                                    The App is not Malware
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    <strong>You’re all clear!</strong>
                                </Typography>
                            </div>
                            <CustomButton
                                padding={"0px 50px"}
                                color={theme.palette.background.default}
                                bgColor={theme.palette.primary.main}
                                hover={theme.palette.info.main}
                                label={"Done"}
                                type={"button"}
                                height="35px"
                                onClick={handleClose}
                            />
                        </div>
                    )
                ) : (
                    <div className={styles.noMalwareCard}>
                        <Typography variant="body1" color="textSecondary" align="center">
                            Search Results here
                        </Typography>
                    </div>
                )}
            </Box>
        </div>
    );
};

export default MalwareSearch;
