import { Button, useTheme } from "@mui/material";
import buttons from "./buttons.module.scss";

const CustomButton = ({
  label,
  disabled,
  Icon,
  onClick,
  bgColor,
  color = "#fff",
  hover,
  padding = "0 10px",
  fullWidth = false,
  type = "",
  height = "48px"
}) => {
  const theme = useTheme(); // Access the theme

  return (
    <Button
      className={buttons.customButton}
      fullWidth={fullWidth}
      type={type}
      disabled={disabled}
      sx={{
        backgroundColor: disabled ? theme.palette.gray.light : bgColor,
        color: disabled ? theme.palette.gray.main : color,
        height: height,
        padding: padding,
        "&:hover": {
          backgroundColor: disabled ? theme.palette.gray.light : hover,
        },
      }}
      onClick={onClick}
      endIcon={
        Icon ? (
          <div
            style={{
              backgroundColor: disabled ? theme.palette.gray.main : color,
              color: disabled ? theme.palette.gray.light : bgColor,
            }}
            className={buttons.iconWrapper}
          >
            <Icon />
          </div>
        ) : null
      }
    >
      {label}
    </Button>
  );
};

export default CustomButton;
